






























import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'Stepper',
  computed: {
    ...mapGetters('appointments', [
      'pageList',
      'complete'
    ]),
    pageListKeys (): string[] {
      return Object.keys(this.pageList)
    }
  },
  methods: {
    itemWidth (i: number): number {
      if (i === this.pageListKeys.length) {
        return (100 / this.pageListKeys.length) + (1 * (i - 1))
      } else {
        return (100 / this.pageListKeys.length) - 1
      }
    }
  }
})
