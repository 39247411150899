



















import Vue from 'vue'
import Stepper from '@/pages/appointment/components/Stepper.vue'

export default Vue.extend({
  name: 'Page',
  components: {
    Stepper
  }
})
